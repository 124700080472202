import getYouTubeID from 'get-youtube-id';

import { getWindow } from '@r-client/shared/util/core';

import { E_VIDEO_TYPE, IMedia, IYouTubeVideo } from './types';

export const getDevicePixelRatio = (): number | undefined => {
  const wnd = getWindow();
  if (!wnd) {
    return undefined;
  }
  return wnd.devicePixelRatio;
};

export const is2xDisplay = (): boolean => getDevicePixelRatio() === 2;

export function getYouTubePreview(url: string) {
  let id = getYouTubeID(url);
  const youTubeUrl = 'https://img.youtube.com/vi/';

  if (!id && url.length === 11) {
    id = url;
  }

  return {
    default: `${youTubeUrl}${id}/default.jpg`, // 120x90 - always present
    medium: `${youTubeUrl}${id}/mqdefault.jpg`, // 320x180
    high: `${youTubeUrl}${id}/hqdefault.jpg`, // 480x360 - always present
    sd: `${youTubeUrl}${id}/sddefault.jpg`, // 640x480 - not always present
    max: `${youTubeUrl}${id}/maxresdefault.jpg`, // 1280x720 - not always present
  };
}

export const isYouTubeVideoHandler = (
  media: IMedia | undefined
): media is IYouTubeVideo => {
  return media?.type === E_VIDEO_TYPE.youTubeUrl;
};
