export enum E_VIDEO_TYPE {
  video = 'video',
  youTubeUrl = 'youTubeUrl', // e.g. youTube video etc.
}

export interface IImage {
  imageUrl: string;
  imageUrl2x: string;
}

export interface IMediaBase {
  thumb?: IImage;
  preview?: IImage;
  videoUrl: string;
}

export interface IVideo extends IMediaBase {
  type: E_VIDEO_TYPE.video;
}
export interface IYouTubeVideo extends IMediaBase {
  type: E_VIDEO_TYPE.youTubeUrl;
  youTubePreview?: string;
  youTubeThumb?: string;
}

export type IMedia = IVideo | IYouTubeVideo;

export enum E_VIDEO_ASPECT_RATIO {
  standard = 'standard',
  widescreen = 'widescreen',
  cinematic = 'cinematic',
}
