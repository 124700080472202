import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import CN from 'clsx';

import { Box, Text } from '@r-client/shared/ui/core';

import { IPlayerMethods, IPlayerProps, IVideoFile, Player } from './player';
import { E_VIDEO_ASPECT_RATIO, IVideo, IYouTubeVideo } from './types';
import { isYouTubeVideoHandler } from './utils';

import styles from './video.module.scss';

export interface IVideoMethods {
  pause: (() => void) | undefined;
}

export interface IVideoProps
  extends Pick<IPlayerProps, 'options' | 'borderRadius'> {
  className?: string;
  media: IVideo | IYouTubeVideo | null;
  onPlay?: () => void;
  onPlaying?: () => void;
  onFullscreen?: (flag: boolean) => void;
  aspectRatio: E_VIDEO_ASPECT_RATIO;
  iconSize?: 'small' | 'default';
}

export const Video = forwardRef<IVideoMethods, IVideoProps>(function Video(
  {
    className,
    media,
    onPlay,
    onPlaying,
    onFullscreen,
    borderRadius,
    aspectRatio,
    iconSize,
    ...playerProps
  },
  forwardingRef
) {
  const [previewPlayClicked, setPreviewPlayClicked] = useState(false);
  const playerRef = useRef<IPlayerMethods | null>(null);
  const isYouTubeVideo = media && isYouTubeVideoHandler(media);
  const playerClass = iconSize === 'small' ? styles.smallIcon : '';

  useImperativeHandle(forwardingRef, () => ({
    pause: () => playerRef?.current?.pause?.(),
  }));

  const preview = useMemo(() => {
    return (
      (media && media.preview) ??
      (isYouTubeVideo
        ? {
            imageUrl: media.youTubePreview,
            imageUrl2x: media.youTubePreview,
          }
        : undefined)
    );
  }, [isYouTubeVideo, media]);

  const videoForPlayer: IVideoFile | undefined = useMemo(() => {
    if (!media?.videoUrl) return;
    return {
      type: isYouTubeVideo ? 'youTube' : 'file',
      url: media?.videoUrl,
      preview,
    };
  }, [isYouTubeVideo, media, preview]);

  const onPreviewPlayClicked = () => {
    setPreviewPlayClicked(true);
    playerRef?.current?.play?.();
  };

  return (
    <Box
      w="100%"
      className={CN(className, {
        [styles.aspectRatioWidescreen]:
          aspectRatio === E_VIDEO_ASPECT_RATIO.widescreen,
        [styles.aspectRatioStandard]:
          aspectRatio === E_VIDEO_ASPECT_RATIO.standard,
        [styles.aspectRatioCinematic]:
          aspectRatio === E_VIDEO_ASPECT_RATIO.cinematic,
      })}
    >
      <Box
        w="100%"
        h="100%"
        borderRadius={borderRadius}
        className={CN(styles.main, playerClass)}
      >
        <Box w="100%" h="100%" bg="gray-f2" className={styles.preview}>
          <Box w="100%" h="100%" className={styles.content}>
            {!previewPlayClicked ? (
              <Box
                className={styles.placeholder}
                onClick={onPreviewPlayClicked}
              >
                {media?.type === 'video' && !preview?.imageUrl && (
                  <video className={styles.content} preload="metadata">
                    <source src={`${media?.videoUrl}#t=0.5`} />
                    Your browser does not support the video tag.
                  </video>
                )}
                {preview?.imageUrl && (
                  <img
                    alt="Video preview"
                    className={styles.previewImage}
                    src={preview.imageUrl}
                    srcSet={`${preview.imageUrl} 1x,${preview.imageUrl2x} 2x`}
                  />
                )}
                <Box className={styles.play}>
                  <Box className={styles.playIcon} />
                  <Text
                    className={styles.playText}
                    as="span"
                    type="bodySmall"
                    weight="400"
                    align="left"
                    color="white"
                    m="0.25brm 0 0 3.125brm"
                  >
                    Play
                  </Text>
                </Box>
              </Box>
            ) : null}

            {videoForPlayer && (
              <Box
                w="100%"
                h="100%"
                className={CN(styles.plyrWrapper, {
                  [styles.hidden]: !previewPlayClicked,
                })}
              >
                <Player
                  {...playerProps}
                  ref={playerRef}
                  video={videoForPlayer}
                  iconSize={iconSize}
                  onPlay={onPlay}
                  onPlaying={onPlaying}
                  onFullscreen={onFullscreen}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
