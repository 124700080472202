import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import CN from 'clsx';
import Plyr, { APITypes } from 'plyr-react';

import { Box, IBoxProps } from '@r-client/shared/ui/core';

import { is2xDisplay } from '../utils';

import styles from './player.module.scss';
export interface IPlayerMethods {
  pause: (() => void) | undefined;
  play: (() => void) | undefined;
  video: IVideoFile;
}

export interface IVideoFile {
  type: 'file' | 'youTube';
  url: string;
  preview?: {
    imageUrl2x?: string;
    imageUrl?: string;
  };
}

export interface IPlayerProps extends Pick<IBoxProps, 'borderRadius'> {
  className?: string;
  video: IVideoFile;
  options?: Omit<Plyr.Options, 'autoplay' | 'controls' | 'youtube'>;
  iconSize?: 'small' | 'default';
  onPlaying?: () => void;
  onPlay?: () => void;
  onPaused?: () => void;
  onFullscreen?: (flag: boolean) => void;
}

export const Player = forwardRef<IPlayerProps, IPlayerProps>(function Player(
  {
    className,
    video,
    options,
    iconSize,
    onPlaying,
    onPlay,
    onPaused,
    onFullscreen,
    borderRadius,
  },
  forwardingRef
) {
  const plyrRef = useRef<APITypes | null>(null);

  const plyrOptions: Plyr.Options = useMemo(
    () => ({
      ...options,
      controls: [
        'play-large',
        'play',
        'pause',
        'progress',
        'volume',
        'mute',
        'fullscreen',
      ],
      youtube: {
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
      },
      autoplay: false,
      playsinline: true,
      fullscreen: {
        iosNative: true,
      },
    }),
    [options]
  );

  useImperativeHandle(forwardingRef, () => ({
    play: () => plyrRef?.current?.plyr?.play?.(),
    pause: () => plyrRef?.current?.plyr?.pause?.(),
    video: video,
  }));

  const plyrSource = useMemo(() => {
    const src = `${video.url}${!video.preview ? '#t=0.1' : ''}`;
    return {
      type: 'video',
      sources: [
        {
          src,
          provider: video.type === 'youTube' ? 'youtube' : 'html5',
        },
      ],
      poster:
        video.type === 'youTube'
          ? undefined
          : is2xDisplay()
          ? video.preview?.imageUrl2x
          : video.preview?.imageUrl,
    } as Plyr.SourceInfo;
  }, [video]);

  const plyrRefWithCallbacks = useCallback(
    (node: APITypes) => {
      if (node !== null && node.plyr.on) {
        plyrRef.current = node;

        const onEnterFS = () => onFullscreen?.(true);
        const onExitFS = () => onFullscreen?.(false);
        if (onPlaying) {
          node.plyr.on('playing', onPlaying);
        }
        if (onPlay) {
          node.plyr.on('play', onPlay);
        }
        if (onPaused) {
          node.plyr.on('pause', onPaused);
        }
        node.plyr.on('enterfullscreen', onEnterFS);
        node.plyr.on('exitfullscreen', onExitFS);
        return () => {
          if (onPlaying) {
            node.plyr.off('playing', onPlaying);
            node.plyr.off('play', onPlaying);
          }
          if (onPaused) {
            node.plyr.off('pause', onPaused);
          }
          node.plyr.off('enterfullscreen', onEnterFS);
          node.plyr.off('exitfullscreen', onExitFS);
        };
      }
    },
    [onPlaying, onPaused, onFullscreen]
  );

  const plyrClass = iconSize === 'small' ? styles.smallIcon : styles.main;

  return (
    <Box className={CN(className, plyrClass)} borderRadius={borderRadius}>
      <Plyr
        ref={plyrRefWithCallbacks}
        source={plyrSource}
        options={plyrOptions}
      />
    </Box>
  );
});
